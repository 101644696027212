input:focus {
  outline: none !important;
  border: none;
}

input {
  border: none;
}

form {
  margin-top: -20px;
}

.collapse-form {
  max-height: 0px;
}

.collapse-chatbox {
  height: 48px;
}

.expand-form {
  height: 60px;
}

#search-users:focus {
  outline: none !important;
  border: none;
}

.hide-msg-form {
  visibility: hidden;
}

.show-msg-form {
  visibility: initial;
}

#chatbox {
  width: 350px;
}

#display {
  height: 400px;
}

@media (max-width: 350px) {
  #chatbox {
    width: 300px;
    /* padding-right:20px; */
  }

  #display {
    height: 250px;
    /* margin-right: 10px; */
  }
}
