@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto" sans-serif;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  overflow-x: hidden;
  font-family: Roboto;
  background-color: #fafafa;
  font-size: 0.95rem;
}

.form-select {
  width: 180px;
}

#arrowCircle {
  border: 1px solid #c6c7c8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 238px;
  background-color: #fff;
  cursor: pointer;
}

#arrowOutCircle {
  border: 1px solid #c6c7c8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 59px;
  background-color: #fff;
  cursor: pointer;
}

/* root container */
.top-container {
  display: grid;
  grid-template-columns: 260px calc(100vw - 260px);
  min-height: 100vh;
  width: 100vw;
}
.top-container-large {
  display: grid;
  grid-template-columns: 80px calc(100vw - 80px);
  min-height: 100vh;
  width: 100vw;
}

.top-container-large .addComponentsData {
  margin: 0 10rem;
}

.displayContainer {
  position: relative;
  /* margin-left: 260px; */
  min-width: 800px;
  max-width: 1400px;
  overflow: hidden;
}

.components {
  min-height: 100vh;
}

.componentsData {
  margin: 0 20px;
}

.addComponentsData {
  margin: 0 4rem;
  display: flex;
  justify-content: center;
  background-color: #fff;
  margin-top: 60px;
  border-radius: 10px;
  position: relative;
}

.heading {
  margin: 10px 20px;
}

.working-here {
  position: relative;
}

/* addUser */
.hide {
  display: none;
}

.show {
  display: block;
}

.working-here ul {
  position: absolute;
  list-style-type: none;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 1001;
  box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px,
    rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
  border-radius: 5px;
  top: 0;
  left: -120px;
  display: none;
}

.working-here:hover > ul {
  display: block;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  background-color: #fff;
  box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px,
    rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
  border-radius: 5px;
  margin: 20px;
}

.login button {
  margin-top: 20px;
}

.sortButton {
  background-color: transparent;
  border: none;
  color: #1475cf;
  font-size: large;
}

#action_context li {
  padding: 5px 10px;
}

#action_context button {
  background-color: transparent;
  border: none;
  padding: 10px;
}

#action_context button:hover {
  border-radius: 20px;
  background-color: #fafafa;
}

.title {
  margin-bottom: 5px;
}

.subtitle {
  color: #646464;
}

#searchInput {
  margin: 50px 20px;
  border-radius: 5px;
  width: 400px;
  height: 40px;
  border: 2px solid #000;
  padding: 10px;
}

.btn {
  margin: 0 20px;
}

.table-card {
  margin-top: 60px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 72px;
}

tbody,
td,
tfoot,
thead,
tr {
  border-style: none;
}

.table {
  /* border: 1px solid #000; */
  border: 1px solid #cccccc;
}

th {
  font-weight: 500;
  border-style: none;
}

.table > :not(:first-child) {
  /* border-top: 1px solid currentColor; */
  border: 1px solid #ccc;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: #f6f7f7;
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  color: #212529;
}

#search {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}

.cta {
  /* height: 40px; */
  /* width: 120px; */
  background-color: #000;
  color: #fff;
  margin: 0;
}

.btn {
  background-color: #1f1f1f !important;
  color: #fff;
  margin: 0;
}

.btn:hover {
  color: white !important;
  background-color: #353935 !important;
}

#add_client_group {
  display: flex;
  grid-template-columns: 0.5fr 1fr;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0 20px; */
  padding-right: 20px;
  margin: 0 20px;
}

#action {
  display: flex;
  flex-direction: column;
}

#action div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
  margin: 1px;
}

.place-items-center {
  place-items: center;
}

/* form action buttons */
.action-buttons {
  border: none;
  background-color: transparent;
  margin: 2px;
  font-size: 20px;
}

.sticker-mgt {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

#stickerCategory {
  border: 1px solid #ced4da;
  background-color: #fff;
  padding: 10px;
}

.detailsMargin {
  margin: 20px;
}

/* media queries */

/* below 480px mobile devices*/
@media screen and (max-width: 500px) {
  .components {
    width: 100vw;
    padding: 0 10px;
    margin-top: 80px;
  }
  .componentsData {
    margin: 0 10px;
    width: 95%;
  }
}

/* 481 to 768px ipads, tablets */
@media screen and (min-width: 500px) and (max-width: 768px) {
  .top-container {
    /* display: grid; */
    /* grid-template-rows: .5fr 1.5fr; */
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
  }

  .components {
    width: 100vw;
    padding: 0 10px;
    margin-top: 80px;
  }

  .componentsData {
    margin: 0 10px;
    width: 97%;
    overflow-x: hidden;
  }

  .displayContainer {
    margin-left: 0;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
  }
  .top-container-large {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
  }
}

/* 769px to 1024px: small screens, laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  /* .components {
        width: 98%;
    } */
}

/* 1025px to 1200px: desktop and large screens */
@media screen and (min-width: 1025px) and (max-width: 1200px) {
}

/* 1201px to more: large screens */
@media screen and (min-width: 1201px) {
  td {
    width: 35rem;
  }
}

.avatarBG {
  background-color: rgb(143, 140, 140);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
}

.profile-link {
  cursor: pointer;
}

.profile-menu {
  display: block;
  right: 0;
  left: auto;
}

/* click outside */
.sharebtn {
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
  font-weight: bolder;
}

#mySharedown {
  display: none;
}

#mySharedown.show {
  display: block;
}

hr {
  color: rgba(0, 0, 0, 0.15);
}

.showmydropdown-menu {
  position: absolute;
  z-index: 1000;
  display: block;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  left: -120px;
}

.mydropdown-menu {
  position: absolute;
  z-index: 1000;
  display: block;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  left: -120px;
}

#mySharedown li {
  padding: 5px 10px;
  cursor: pointer;
}

#mySharedown a {
  width: 100%;
  padding: 5px 10px;
  color: #000;
  text-decoration: none;
  display: block;
}

#mySharedown a:hover {
  background-color: #fafafa;
}

#mySharedown li:hover {
  background-color: #fafafa;
}

#mySharedown button {
  background-color: transparent;
  border: none;
  padding: 10px;
}

#mySharedown button:hover {
  border-radius: 20px;
  background-color: #fafafa;
}

.actionDiv {
  display: flex;
  align-items: center;
  /* grid-template-columns: 1fr 1fr; */
}

.actionDiv i {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-right: 10px;
}

.btn-primary {
  color: #fff;
  /* background-color: #0d6efd; */
  background-color: #1475cf;
  border-color: #0d6efd;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  /* background-color: #0a58ca; */
  background-color: #1475cf;
  border-color: #0a53be;
}

.no-table-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.no-table-data i {
  font-size: 4rem;
  color: gray;
}

.no-table-data p {
  color: gray;
}

.dateRange {
  border: 1px solid #ced4da;
  /* background-color: #1475cf; */
  /* color: #fff; */
  border-radius: 3px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.loader-wrapper {
  z-index: 100;
  position: absolute;
  width: 100%;
  background: rgba(245, 245, 245, 0.8);
  height: 100%;
  display: flex;
}

@media print {
  .top-container {
    display: none;
  }
  .top-container-large {
    display: none;
  }

  .hideOnPrint {
    display: none;
  }

  #stickerPrint {
    display: block;
  }

  .modal-content {
    border: none;
  }
}

.generator__password {
  position: relative;
  background: rgb(255 255 255);
  color: #000;
  margin-bottom: 15px;
  word-break: break-word;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.generator__password h3 {
  font-weight: 500;
  text-align: left;
  margin: 0;
  /* padding: 0px 37px 0 0; */
  font-size: 17px;
  line-height: 33px;
  min-height: 20px;
  text-align: center;
}

.copy__btn {
  position: absolute;
  background: #1f1f1f;
  color: #fff;
  border: none;
  height: 42px;
  padding: 0 12px 0 12px;
  cursor: pointer;
  right: 3px;
}
.copy__btn svg {
  font-size: 20px;
}

.password-h3 {
  background-color: #fafafa;
  height: 42px;
}

@media (max-width: 360px) {
}

@media (max-width: 480px) {
  .display-left {
    width: 100vw;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 820px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 1200px) {
}

/* edit forms */
addFormGroups {
  display: flex;
  flex-direction: column;
  align-items: start;
}

/* addPolicyForm */
#addPolicyForm {
  padding: 10px;
  width: 100%;
}

.navbar-toggler-icon {
  color: red !important;
}
