.upload-wrapper{
    margin-bottom: 10px;
    /* padding: 20px; */
}

#form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #1475cf;
    height: 167px;
    cursor: pointer;
    border-radius: 5px;
}

#upload_heading{
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
}

#form :where(#upload-icon, p){
    color: #1475cf;
}


#upload-icon{
    font-size: 50px;
}

#form p{
    margin-top: 15px;
    font-size: 16px;
}

.progress-area{
    overflow: hidden;
}

.uploaded-row{
    background-color: #e9f0ff;
    margin: 10px 0;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 15px 20px;
    border-radius: 5px;
}

.uploaded-icon{
    color: #1475cf;
}