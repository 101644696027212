.classic {
    margin-right: 10px;
}

.search { 
    width:30%;
}


@media (max-width:360px) {
    .search {
        display: grid;
        /* grid-template-columns: 1fr; */
    }

    #daily-reports {
        margin-left: -30px;
    }

    #group-1-reports {
        flex-direction: column;
        align-items: flex-start;
    }

    #group-2-reports {
        flex-direction: column;
        align-items: flex-start;
    }
}  

@media (max-width:480px)  {
    .search {
        display: flex;
        flex-direction: column;
    }

    #daily-reports {
        margin-left: -30px;
    }

    /* .componentsData {
        display:flex;
        flex-direction:column;
    } */
    #group-1-add-claims {
        display: flex;
        flex-direction: column;
    }

    #group-2-add-claims {
        display: flex;
        flex-direction: column;
    }

    #group-1-reports {
        flex-direction: column;
        align-items: flex-start;
    }

    #group-2-reports {
        flex-direction: column;
        align-items: flex-start;
    }


    
}

@media (max-width:768px) and (min-width: 481px) {
    .search {
        display: grid;
        grid-template-columns: 1fr 1fr;        
    }

    
}

@media (max-width:820px) and (min-width: 767px){
    .search {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    #group-2-add-claims {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    #group-1-add-claims {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    #graph-space {
        height: 35vh;
    }

    /* .expanded-menu-chat-container {
        padding-right: 30px;
    } */


}

@media (min-width:1201px) {
    .classic {
        margin-right: 15px;
    }

    .search {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    #group-1-add-claims {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    #group-2-add-claims {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .expanded-menu-chat-container {
        padding-right: 280px;
    }

    .chat-container { 
        padding-right:100px;
    }
}

@media (max-width:1200px) and (min-width:821px) {
    .chat-container {
        padding-right: 200px;
    }

    .search { 
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    #group-1-add-claims {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    #group-2-add-claims {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .chat-container {
        padding-right: 100px;
    }

    .expanded-menu-chat-container {
        padding-right: 280px;
    }

}

@media (max-width: 768px) and (min-width: 480px) {
    .chat-container {
        padding-right: 30px;
    }

    .expanded-menu-chat-container {
        padding-right: 30px;
    }
    

}

@media (min-width: 769px) and (max-width: 820px) {
    .chat-container {
        padding-right: 30px;
    }

    .expanded-menu-chat-container {
        padding-right: 280px;
    }
}

/* .dashboard-cards {

} */

