.auth-wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.auth-wrapper img {
  margin-bottom: 40px;
}

.auth-wrapper form {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  background: #fff;
  border-radius: 5px;
  /* color: #8e8e93; */
  padding: 25px 50px;
  box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px,
    rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
}

.auth-wrapper form p {
  font-size: 1.1rem;
}

#password {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#password_input {
  border: none;
  width: 95%;
}

.visibilityIcon {
  float: right;
  margin-right: 5px;
  margin-top: auto;
  position: relative;
  z-index: 2;
}

.visibilityIcon:hover {
  color: #000;
}

#checkbox {
  margin-right: 5px;
}

/* #email:focus {
  outline: 1px solid #1475cf;
}

#password_input:focus .visibilityIcon{
    border: 1px solid #1475cf;
} */

.auth-wrapper .login-inputs {
  display: flex;
  flex-direction: column;
  /* padding: 10px 30px; */
  margin-bottom: 20px;
}

.login-inputs input {
  border-radius: 5px;
  padding: 5px 5px;
  border: 1px solid #9a9a9a;
}

.login-inputs input:focus {
  border: 1px solid #9a9a9a;
}

.login-inputs input:focus {
  outline: none;
}

#password {
  border-radius: 5px;
  border: 1px solid #9a9a9a;
}

#password_input:focus {
  outline: none;
}

/* positioning the visibility */
#password {
  position: relative;
}

#visible {
  position: absolute;
  left: 60%;
  top: 57%;
  color: #000;
  border: 1px solid red;
}

#submit_login input {
  margin: 0;
}

#submit_login a {
  text-decoration: none;
}

#enter {
  font-size: 0.9rem;
}

#forgotSubmit {
  width: 100%;
  margin: 0;
}

/* #forgotRemember {
  text-align: center;
  color: #1475cf;
  margin: 0;
} */

#forgotLink {
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 0;
}

@media screen and (max-width: 780px) {
  .auth-wrapper form {
    padding: 25px 25px;
  }
}
