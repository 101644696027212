.pagination {
	float: right;
	margin: 0 0 5px;
}
.pagination li a {
	border: none;
	font-size: 13px;
	min-width: 30px;
	min-height: 30px;
	color: #999;
	margin: 0 2px;
	line-height: 30px;
	border-radius: 2px !important;
	text-align: center;
	padding: 0 6px;
}
.pagination li a:hover {
	color: #666;
}	
.pagination li.active a, .pagination li.active a.page-link {
	background: #1475cf;
}
.pagination li.active a:hover {        
	background: #0397d6;
}

.pagination li.disabled i {
	color: #ccc;
}


.pagination li i {
	font-size: 16px;
	padding-top: 6px
}


.hint-text {
	float: left;
	margin-top: 10px;
	font-size: 13px;
} 

.clearfix{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.page-item button{
	background-color: #1f1f1f;
	border: 1px solid #1f1f1f;
	margin: 0 3px;
	border-radius: 50%;
	color: #fff;
	width: 25px;
	height: 25px;
	border: none;
}

.page-item.disabled button{
	background-color: #f6f7f7;
	color: #d2d2d3;
	border: 1px solid #d2d2d3;
}
	